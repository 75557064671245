(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Entitats
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('Entitats', Entitats);

  function Entitats($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/entities/:id', {id: '@_id'}, {
      update: {
        method: 'PUT'
      }
    });
  }
}());
